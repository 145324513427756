<template>
  <div class="themelogout" :style="{background: $store.getters.getColor('bgColorSecundary')}">
    <v-container class="fill-height pa-8">
      <v-row no-gutters class="mb-0">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <img v-if="$store.getters.getLogoClaro" :src="$store.getters.getLogoClaro" width="110" class="mt-4 mb-5" />
           <h1 v-else class="white--text">{{ $store.getters.getAppName}}</h1>
        </v-col>
      </v-row>
      <v-row no-gutters class="align-self-end align-self-sm-center">
        <v-col cols="12">
          <h1 class="white--text font-weight-regular mb-4 mt-8 title-welcome" v-html="$store.getters.getWelcome.titulo">
          </h1>
          <div class="white--text mb-2 text-welcome" v-html="$store.getters.getWelcome.descricao">
          </div>
          <div class="white--text mb-5 text-welcome" v-html="$store.getters.getWelcome.saibaMais">
          </div>
        </v-col>
        <v-col cols="12" class="d-flex justify-end">
          <v-btn :to="{name: 'validacao'}" color="white" dark right fab class="mt-10">
            <v-icon color="primary">mdi-chevron-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
@import '~@/styles/variables';

.title-welcome {
  line-height: 1;
  @media (min-width: $media-sm) {
    font-size: 52px;
  }
}
.text-welcome {
  @media (min-width: $media-sm) {
    font-size: 22px;
    line-height: 27px;
  }
}
</style>
