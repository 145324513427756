var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "themelogout",
      style: { background: _vm.$store.getters.getColor("bgColorSecundary") },
    },
    [
      _c(
        "v-container",
        { staticClass: "fill-height pa-8" },
        [
          _c(
            "v-row",
            { staticClass: "mb-0", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  staticClass: "d-flex flex-column justify-center align-center",
                  attrs: { cols: "12" },
                },
                [
                  _vm.$store.getters.getLogoClaro
                    ? _c("img", {
                        staticClass: "mt-4 mb-5",
                        attrs: {
                          src: _vm.$store.getters.getLogoClaro,
                          width: "110",
                        },
                      })
                    : _c("h1", { staticClass: "white--text" }, [
                        _vm._v(_vm._s(_vm.$store.getters.getAppName)),
                      ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "v-row",
            {
              staticClass: "align-self-end align-self-sm-center",
              attrs: { "no-gutters": "" },
            },
            [
              _c("v-col", { attrs: { cols: "12" } }, [
                _c("h1", {
                  staticClass:
                    "white--text font-weight-regular mb-4 mt-8 title-welcome",
                  domProps: {
                    innerHTML: _vm._s(_vm.$store.getters.getWelcome.titulo),
                  },
                }),
                _c("div", {
                  staticClass: "white--text mb-2 text-welcome",
                  domProps: {
                    innerHTML: _vm._s(_vm.$store.getters.getWelcome.descricao),
                  },
                }),
                _c("div", {
                  staticClass: "white--text mb-5 text-welcome",
                  domProps: {
                    innerHTML: _vm._s(_vm.$store.getters.getWelcome.saibaMais),
                  },
                }),
              ]),
              _c(
                "v-col",
                { staticClass: "d-flex justify-end", attrs: { cols: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mt-10",
                      attrs: {
                        to: { name: "validacao" },
                        color: "white",
                        dark: "",
                        right: "",
                        fab: "",
                      },
                    },
                    [
                      _c("v-icon", { attrs: { color: "primary" } }, [
                        _vm._v("mdi-chevron-right"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }